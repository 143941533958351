import { ASSIGNMENT, GET, DELETE, GET_ALL, UPSERT } from '@/store/actions.type'
import { mapState } from 'vuex'
import { CONNECTION } from '@/store/actions.type'
import helpers from '@/helpers/utils'

export const createMixin = (plural) => ({
	data() {
		return {
			item: {
				name: ''
			},
			available: false,
			uiState: 'submit not clicked',
			errors: false,
			empty: true,
			formTouched: false,
			loaded: false,
			confirm: {
				title: 'Confirm Delete',
				message: 'Are you sure you want to delete?',
				open: false,
				okButton: 'Delete'
			}
		}
	},
	mounted() {
		if (this.$route.params.id) {
			let hasCurrent = this.$store.state[plural].current.id === this.$route.params.id
			if (!hasCurrent) {
				this.$store.dispatch(`${plural}/${GET}`, this.$route.params.id).then(() => {
					this.item = { ...this.$store.state[plural].current }
				})
			} else {
				this.item = { ...this.$store.state[plural].current }
			}
			this.loaded = true
		}

		this.$store.dispatch(`connections/${GET_ALL}`)
	},
	methods: {
		showConfirmDelete() {
			this.confirm.open = true
		},
		deleteConfirmed(isModel) {
			this.remove(isModel)
			this.confirm.open = false
		},
		deleteCancelled() {
			this.confirm.open = false
		},
		async save() {
			this.$v.$touch()
			this.formTouched = !this.$v.item.$anyDirty
			this.errors = this.$v.item.$anyError
			this.uiState = 'submit clicked'

			if (this.errors === false && this.formTouched === false) {
				if (this.$v.item.$model) {
					this.$store.dispatch(`${plural}/${UPSERT}`, this.item).then(() => {
						this.$analytics.logEvent('save_content', { content_type: plural, ...this.$v.item.$model })
						this.$router.push({ name: plural })
					})
				}
			}
		},
		async remove(isModel = false) {
			if (isModel) {
				await this.removeModelConnections()
			} else {
				await this.removeConnections()
			}

			if (this.item.assignedTo) {
				await this.unassign()
			}
			this.$store.dispatch(`${plural}/${DELETE}`, this.item.id).then(() => {
				this.$analytics.logEvent('delete_content', { content_type: plural, ...this.$v.item.$model })
				this.$router.push({ name: plural })
			})
		},
		async removeModelConnections() {
			var promises = []
			let connections = this.$store.state.connections.all
			let matchedConnections = connections.filter((c) => c.modelId === this.item.id)
			for (const c of matchedConnections) {
				promises.push(this.$store.dispatch(`connections/${DELETE}`, c.id))
			}

			return Promise.all(promises)
		},
		async removeConnections() {
			var promises = []
			let connections = this.$store.state.connections.all
			let matchedConnections = connections.filter((c) => c.source === this.item.id || c.target === this.item.id)
			for (const c of matchedConnections) {
				promises.push(this.$store.dispatch(`connections/${DELETE}`, c.id))
			}

			return Promise.all(promises)
		},
		async unassign() {
			let info = {
				id: this.item.id,
				sourceCollectionName: this.$route.meta.collectionName,
				sourceSingleName: this.$route.meta.singleName,
				action: this.$route.meta.unassignAction
			}

			for (const assignedModel of Object.values(this.item.assignedTo)) {
				let x = { ...info, targetId: assignedModel.id }
				this.$store.dispatch(`models/${info.action}`, x)
			}
		}
	}
})

export const configureFieldMixin = () => {}

export const configureMixin = (assigns = true, isRouter = true) => ({
	data() {
		return {
			isAssigned: false,
			isAssignedInitialValue: false,
			sourceCollectionName: '',
			sourceSingleName: '',
			assignAction: '',
			unassignAction: '',
			configId: '',
			assignedLeft: 'target',
			assignedRight: 'source',
			allConnections: [],
			initialAssigned: [],
			initialUnassigned: [],
			filterValue: '',
			item: {}
		}
	},
	computed: {
		...mapState({
			model: (state) => state.models.current,
			connections: (state) => state.connections.all.filter((c) => c.modelId === state.models.current.id),
			// item(state) {
			// 	return state[this.plural].current
			// },
			modelItems(state) {
				return state.models.current[this.operates] ? Object.values(state.models.current[this.operates]) : []
			},
			accountItems(state) {
				return state[this.operates] ? state[this.operates].all : []
			}
		}),
		items: function() {
			return this.accountItems.filter((elem) => this.modelItems.find(({ id }) => elem.id === id))
		},
		assigned: function() {
			return this.searchFilter.filter((o) => o.isChecked)
		},
		unassigned: function() {
			return this.searchFilter.filter((o) => !o.isChecked)
		},
		sourceConnections() {
			return this.connections.filter((c) => c.source === this.item.id)
		},
		targetConnections() {
			return this.connections.filter((c) => c.target === this.item.id)
		},
		assignedSame() {
			return this.searchFilter.every((i) => i.isChecked === i.isConnected)
		},
		hasChanged() {
			return this.isAssigned != this.isAssignedInitialValue
		},
		allowSave() {
			return !this.assignedSame || this.hasChanged
		},
		searchFilter: function() {
			let result = [...this.allConnections]
			if (!this.filterValue) return result.sort(this.sorter)

			const filterValue = this.filterValue.toLowerCase()

			const filter = (event) =>
				event.name.toLowerCase().includes(filterValue) || (event.description && event.description.toLowerCase().includes(filterValue))

			return result.filter(filter).sort(this.sorter)
		}
	},
	watch: {
		// eslint-disable-next-line no-unused-vars
		'$route.params.id': function(id) {
			this.setData()
		},
		isAssigned: function() {
			if (this.hasChanged) {
				this.saveConfiguration()
			}
		}
	},
	mounted() {
		this.setData()
	},
	methods: {
		sorter(a, b) {
			if (a.sort > b.sort) {
				return 1
			}
			if (b.sort > a.sort) {
				return -1
			}
			return 0
		},
		saveConfiguration() {
			this.$store.commit('ui/loading', true)
			this.filterValue = ''

			let hasChanged = this.isAssigned != this.isAssignedInitialValue

			if (this.isAssigned && hasChanged) {
				this.assign().then(() => {
					this.isAssignedInitialValue = true
				})
			} else if (!this.isAssigned && hasChanged) {
				this.unassign().then(() => {
					this.isAssignedInitialValue = false
				})
			}

			if (this.isAssigned) {
				this.setConnections()
			} else {
				this.$analytics.logEvent('remove_connection')
				let sourceConnections = this.sourceConnections.map((c) => c.id)
				let targetConnections = this.targetConnections.map((c) => c.id)
				let remove = [...sourceConnections, ...targetConnections]
				this.disconnectBatch(remove)
			}

			this.$store.commit('ui/loading', false)
		},
		setConnections() {
			let assignedList = this.assigned
				.filter((a) => !a.isConnected)
				.map((connectionItem) => {
					let connectionDetail = {}
					connectionDetail[this.assignedRight] = this.item.id
					connectionDetail[this.assignedLeft] = connectionItem.id
					connectionDetail.name = `${connectionItem.name} > ${this.item.name}`
					connectionDetail[this.assignedRight + 'Name'] = this.item.name
					connectionDetail[this.assignedLeft + 'Name'] = connectionItem.name
					connectionDetail.value = 1
					connectionDetail.modelId = this.model.id
					connectionDetail.modelName = this.model.name
					connectionItem.isConnected = true
					return connectionDetail
				})

			if (assignedList.length) {
				this.$analytics.logEvent('set_connection', {
					content_type: this.plural,
					item_type: this.operates,
					model_id: this.model.id,
					items: [assignedList.map((i) => i[this.assignedLeft + 'Name'])]
				})

				this.connectBatch(assignedList)
			}

			let unassignedList = this.unassigned
				.filter((a) => a.isConnected)
				.map((i) => {
					let connection = this.connections.find((e) => e[this.assignedLeft] === i.id && e[this.assignedRight] === this.item.id)
					if (connection) {
						i.isConnected = false
						return connection.id
					}
				})

			if (unassignedList.length) {
				this.disconnectBatch(unassignedList)
			}
		},
		setData() {
			if (isRouter) {
				this.getRouteData()
			} else {
				this.getRouteData()
				this.configId = this.field.id
			}
			this.getItem()
		},
		getRouteData() {
			this.sourceCollectionName = this.$route.meta.collectionName
			this.sourceSingleName = this.$route.meta.singleName
			this.assignAction = this.$route.meta.assignAction
			this.unassignAction = this.$route.meta.unassignAction
			this.configId = this.$route.params.configId
		},
		async getItem() {
			if (this.configId) {
				let hasCurrent = this.configId === this.item.id
				if (!hasCurrent) {
					this.$store.dispatch(`${this.plural}/${GET}`, this.configId).then((item) => {
						this.item = item
						this.setAssigned()
						if (assigns) this.getOptions()
					})
				} else {
					this.setAssigned()
					if (assigns) this.getOptions()
				}
			}
		},
		async getOptions() {
			this.allConnections = this.items.map((i) => ({
				name: i.name,
				sort: helpers.shortName(i.name),
				description: i.description,
				id: i.id,
				isConnected: this.connections.some((e) => e[this.assignedLeft] === i.id && e[this.assignedRight] === this.item.id),
				isChecked: this.connections.some((e) => e[this.assignedLeft] === i.id && e[this.assignedRight] === this.item.id)
			}))

			this.initialAssigned = [...this.allConnections.filter((o) => o.isConnected)]
			this.initialUnassigned = [...this.allConnections.filter((o) => !o.isConnected)]
		},
		setAssigned() {
			this.isAssigned = this.model[this.plural] ? Object.values(this.model[this.plural]).some((i) => i.id === this.item.id) : false
			this.isAssignedInitialValue = this.isAssigned
		},
		connect(connectionData) {
			return this.$store.dispatch(`connections/${CONNECTION.ADD}`, connectionData)
		},
		connectBatch(connectionData) {
			return this.$store.dispatch(`connections/${CONNECTION.ADD_BATCH}`, connectionData)
		},
		disconnect(id) {
			return this.$store.dispatch(`connections/${CONNECTION.REMOVE}`, id)
		},
		disconnectBatch(ids) {
			return this.$store.dispatch(`connections/${CONNECTION.REMOVE_BATCH}`, ids)
		},
		async assign() {
			let assignInfo = {
				id: this.item.id,
				name: this.item.name,
				sourceCollectionName: this.sourceCollectionName,
				sourceSingleName: this.sourceSingleName,
				targetId: this.model.id,
				targetName: this.model.name
			}

			return this.$store.dispatch(`${assignInfo.sourceCollectionName}/${ASSIGNMENT.ADD}`, assignInfo).then(() => {
				return this.$store.dispatch(`models/${this.assignAction}`, assignInfo)
			})
		},
		async unassign() {
			let info = {
				id: this.item.id,
				sourceCollectionName: this.sourceCollectionName,
				sourceSingleName: this.sourceSingleName,
				action: this.unassignAction
			}

			info.targetId = this.model.id
			return this.$store.dispatch(`${info.sourceCollectionName}/${ASSIGNMENT.REMOVE}`, info).then(() => {
				return this.$store.dispatch(`models/${this.unassignAction}`, info)
			})
		},
		getSwitchValue(e) {
			this.isAssigned = e
		}
	}
})
